// require jQuery globally

global.$ = global.jQuery = $

import 'bootstrap'
import 'select2/dist/js/select2.full.min'

const $document = $(document)

$document.ready(() => {
  /** Select2 with images **/
  function addSelectImage(opt) {
    if (!opt.id) {
      return opt.text
    }

    const optionImage = $(opt.element).data('image')

    return optionImage
      ? $(`<span class="select2-img-block">
            <img src="${optionImage}" class="select2-img-flag" alt=""/>
            ${$(opt.element).text()}
           </span>`)
      : opt.text
  }

  const select2Image = $document.find('.select2-img')

  if (select2Image.length) {
    select2Image.select2({
      dropdownCssClass: 'select-flags-box',
      templateResult: addSelectImage,
      templateSelection: addSelectImage,
      width: '100%',
    })
  }

  select2Image.on('select2:select', (e) => {
    const $this = $(e.target)

    window.location = $($this.select2('data')[0].element).data('url')
  })

  var size = frontImages.length
  var x = Math.floor(size * Math.random())
  document.getElementById('frontimage').src = frontImages[x]
})
